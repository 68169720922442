import {Link, withPrefix} from 'gatsby';
import React from 'react';

import {Button} from '../../../components/button';
import {Layout} from '../../../components/layout';

const locale = 'en';

export default () => {
    return (
        <Layout
            title="General terms and conditions for our clients"
            description="Software product development is a comprehensive and complicated process. That's why we have a well-thought-out approach and crystal-clear terms."
            ogImage="terms-conditions-en.png"
            translatedPagePath="/nl/voorwaarden/"
            locale={locale}>
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Our Terms and <br /> Conditions
                        </h2>
                        <div className="display__number">TC</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Software product development is an extensive and complex process. It requires a
                            well-thought-out approach and crystal-clear terms and conditions.
                        </p>
                        <p>
                            See below for our general <span className="highlight">terms and conditions</span>, which
                            apply to all agreements we enter into. The terms and conditions contain various provisions
                            based on parts of the{' '}
                            <a
                                href="https://www.nederlandict.nl/diensten/nederland-ict-voorwaarden/"
                                target="_blank"
                                rel="external nofollow"
                                title="Website of branch association Nederland ICT (Dutch)">
                                Netherlands ICT sector terms and conditions
                            </a>
                            . Together with provisions specifically relating to our unique approach.
                        </p>
                        <p>
                            We give our clients an honest, clear description of how we work &mdash;
                            <span className="highlight">in advance</span>. We do this by providing a detailed,
                            easy-to-understand{' '}
                            <Link to="/en/terms-and-conditions/explanation/" title="Explanation of Approach & Process">
                                explanation
                            </Link>{' '}
                            of our terms and conditions and working processes. This clarifies all aspects of the process
                            before we start work.
                        </p>
                    </div>

                    <div className="content__buttons content__buttons--50">
                        <Button
                            direct
                            url={withPrefix('/files/algemene-voorwaarden.pdf')}
                            name="General terms and conditions (PDF in Dutch)"
                            className="button--download"
                            title="Download our Terms and Conditions as a PDF (Dutch)"
                        />
                        <Button
                            url="/en/terms-and-conditions/explanation/"
                            name="See the detailed explanation"
                            title="Explanation of Approach & Process"
                        />
                    </div>
                </div>
            </article>
        </Layout>
    );
};
